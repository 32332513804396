body{
	background: var(--bg);
	
	background-size: cover;
}

.EventPagebody{
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-top:10vh;
}

.workshopimg{
	width: 100%;
	max-width: 400px;
	border-radius: 40px;
	/* border: 3px solid #b2b7c2; */
	box-shadow: 0 0 20px rgba(172, 112, 255, 0.329);
	-webkit-transition: box-shadow linear 1s;
	transition: box-shadow linear 1s;

}
.about{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.workshops{
	display: flex;
	/* justify-content: ; */
	align-items: center;
	width: 75vw;
	margin-top: 50px;
 border:1px solid rgba(172, 112, 255, 0.329);
	
	box-shadow: 5px 5px 12px rgba(172, 112, 255, 0.329),
                -5px -5px 12px rgba(172, 112, 255, 0.33),
                5px 5px 10px rgba(172, 112, 255, 0.33),
                -5px -5px 10px rgba(172, 112, 255, 0.329);

}

	.btnevent {
		background: linear-gradient(180deg, rgba(98, 212, 237, 0.8) -27.94%, rgba(172, 112, 255, 0.79) 76.47%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		/* text-fill-color: transparent; */
		font-family: 'valorant';
		/* width: 20%; */
		margin-bottom: 10px;
		text-shadow: 0px 4px 6px rgba(172, 112, 255, 0.33);
	
	}


.event-name{
	font-family: valorant;
	background:linear-gradient(180deg, rgba(98, 212, 237, 0.8) -27.94%, rgba(172, 112, 255, 0.79) 76.47%);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 0px !important;
}
.tagline{
	/* width: 30vw; */
	text-align: center;
	font-family: Unna;
	font-size: 20px;
}
.about{
	text-align: center;
}

/* @media (max-width:600px){
	h1{
		font-size: 0.5rem;
	}
	p{
		font-size: 0.5rem;
	}
} */