.registration{
    background: url('../../assets/bgprakarsh.png');
    height: max-content;
    margin-top: 10vh;
    margin-bottom: 0;
    /* width: max-content; */
    background-size: cover;
    box-sizing: border-box;
    
    
    
    /* flex-wrap: wrap; */
    /* flex-direction: column; */

}

.rhead h1{
    font-size: 3rem;
    font-family: 'valorant';
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient( 180deg,rgba(128, 128, 128) 1.25%, rgba(128, 128, 128)1.26%, rgba(255, 255, 255) 1.27%, rgba(255,255,255,0.4) 69.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    letter-spacing: 10px;
    
}
.rcontent{
    /* padding: 20px;  */
    /* margin: 50px; */
    margin-left: 10vh;
    display: grid;
    grid-template-columns: 1fr ;
    /* grid-auto-rows: 300px; */

}
input{
    width:600px;
    margin-top: 20px;
    
}


.first{
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* width: 50px; */
    margin-left: 20vh;
}

.second{
    display: grid;
    grid-template-columns: 1fr ;
    justify-self: center;
    grid-column-start: 1;

    
}

.try{
    display: flex;
    justify-content: center;
    flex-direction: column;
    
}

.pad{
    margin: 20px;
    width: 600px;
}
.try-2{

    display: grid;
    grid-template-columns: 1fr 1fr;
}

 

/* .inputbox input{
    width: 100%;
    padding: 10px;
    height: 52px;
    border: 3px solid  rgba(106, 15, 233); ;
    border-radius: 5px;
    background-color: transparent;
    outline: none;
    color: #fff;
    font-size: 1.4em; 
    position: relative;
    bottom: 118px;
    right: 21px;
    
} */

.r-rect{
    width: 600px;
}

/* .btnprimary {
    width: 120px;
    height: 40px;
    background-color: #1d2a2e;
    border: 1px solid rgb(1, 209, 105);
    border-radius: 5px;
    color: rgb(1, 209, 105);
    position: relative;
    left: 180px;
    bottom: 80px;
    
   
} 


.sbtn{
    width: 120px;
    height: 40px;
    border: solid rgb(1, 78, 73);
    border-radius: 5px;
    position: relative;
    bottom: 35px;
    right: 9px;
} */
