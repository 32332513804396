.arrange{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 5rem auto;
    /* width: 100%; */
    max-width: 1200px;
    padding: 0 20px;
    
}

.titlesponser h1{
    text-align: center;
    font-size: 30px;
}
.arrange div{
    min-height: 250px;
}
.arrange div img{
    width: 100%;
}