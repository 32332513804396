#home {
    width: 100vw;
    overflow: hidden;
}


/* .imageholder {
    width: 500px;
    height: 477px;
    background: url("../../assets/loader.mp4") no-repeat center;
    background-position: center center;
    background-size: cover;
} */

.home__container {
    display: grid;
    grid-template-columns: 50% 35%;
    gap: 10%;
    overflow: hidden;
    margin-top: 5rem;
    padding-bottom: 4rem;
}

.home__content {
    margin-left: 3rem;
}

.home__content .home__title h5 {
    width: 90%;
    margin: 1rem 0 1rem 0;
}

.home__content p {
    margin: 2rem 0 2.6rem;
    color: var(--text-p);
    text-align: center;
    font-size: 20px;
    line-height: 45px;
    font-family: "ubuntu";
}

.glow {
    font-family: "Valorant";
    padding: .15rem;
    /* font-family: "clips"; */
    /* text-transform: uppercase; */
    font-size: 20px;
    width: 60%;
    text-decoration: none;
    /* position: relative; */
    text-align: center;
    /* margin-left: 16rem; */
    background: linear-gradient(
    111.17deg,
    rgba(0, 253, 101, 0.09) 0.84%,
    rgba(0, 253, 101, 0.38) 0.84%,
    rgba(0, 253, 101, 0) 90%,
    rgba(0, 253, 101, 0) 90%,
    rgba(0, 253, 101, 0) 90%
  );
    color: var(--text-p);
    border-radius: 5px;
    
    animation: shine 2s forwards, flicker 3s infinite;
}
.glow  a{
    text-decoration: none;
    color: var(--text-p);
}
.eventLiveText{
    text-align: center;
	font-family: valorant;
	background:linear-gradient(180deg,  rgb(0, 253, 101,0.9) -27.94%, rgba(172, 112, 255, 0.79) 76.47%);
	-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 5em;
}
.eventLive{

    -webkit-text-stroke: 1px rgb(0, 253, 101);
    color: #000c14;
    
    
    
}

.home__logo {
    width: 100%;
    aspect-ratio: 1/1;
    display: grid;
    place-items: center;
}

.home__logo-img {
    overflow: hidden;
}

.home__logo-img p {
    position: absolute;
    font-family: "Valorant";
    font-size: 50px;
    bottom: 8rem;
    right: 10rem;
    align-items: center;
    background: linear-gradient( 180deg, #00fd65 1.25%, #00fd65 1.26%, rgba(0, 253, 101, 0.841667) 1.27%, rgba(0, 253, 101, 0) 69.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  
}
#timeCounter{
    border: 1px solid white;
    align-items: center;
    text-align: center;
    width: 100px;
    height: 200px;
    margin: 0;
}

/* hr{
    border-top: 10px solid #fff;
} */

.Counter{
    display: flex;
    justify-content:center;
    font-size: 90px;
    margin: 0;
    align-items: center;
}

.Counter p{
    font-size: 16px;
    margin: 0;
}
.c-counter{
    margin-left: -3rem;

}

@media(max-width: 600px){
    .eventLiveText{
        font-size: 2em;
    }
    .Counter{
        display: flex;
        justify-content:flex-start;
        font-size: 90px;
        margin: 0;
        align-items: center;
        overflow: hidden;
    }
    .c-counter{
        width: max-content;
        transform: scale(0.8);
        overflow: hidden;
    }
    
    .Counter p{
        font-size: 16px;
        margin: 0;
    }
}

@media screen and (max-width: 1024px) {
    
    .home__container {
        gap: 5rem;
    }

    
    .home__logo {
        width: 50%;
        margin: 2rem auto 4rem;
    }
    .home__content {
        margin-right: 2rem;
    }
    .home__logo {
        width: 85%;
    }
    /* .home__logo-img p {
    font-size: 30px;
  } */
    .home__content p {
        margin: 1rem 0 1.5rem;
    }
    .home__content .glow {
        /* margin-left: 8rem; */
    }
}

@media screen and (max-width: 936px) {
    .eventLiveText{
        font-size: 3em;
    }
    #home {
        padding-top: 100px;
    }

    .glow {
        font-size: 40px;
    }
    .home__container {
        gap: 2rem;
    }
    .home__container {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 0rem;
    }
    /* .video{
        width: 100px;
        /* gap: 2rem; */

    
    .home__logo {
        width: 60%;
        margin: 2rem 1rem 4rem;
    }
    .home__content .home__title h5 {
        font-size: 35px;
        line-height: 50px;
    }
    /* .home__logo-img p {
    font-size: 30px;
  } */
    .home__content p {
        font-size: 20px;
        margin: 1rem 0 1.5rem;
    }
    .home__content .glow {
        text-align: center;
        font-size: 25px;
    }
}

@media screen and (max-width: 600px ) {
    .eventLiveText{
        font-size: 3em;
    }
    .home__container {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 0rem;
    }
    .home__logo {
        width: 65%;
        margin: 1rem 4.75rem;
    }
    .home__logo-img p {
        margin-bottom: 18rem;
        right: 8rem;
    }
    .home__content {
        text-align: center;
        margin-top: -6rem;
        padding-top: 3rem;
    }
    .home__content p {
        margin: 1.5rem 0rem 1.5rem 0rem;
        line-height: 30px;
    }
    .home__content .glow {
        /* position: relative;
    margin-left: 6rem;
    margin-top: 3rem; */
    font-size: 15px;
    }
    .video {
        width: 300px;
    }  
}