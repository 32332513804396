.gimg {
    padding: 10px;
    width: 300px;
}

.gdiv {
    text-align: center;
    padding-top: 20px;
    width: 100%;
    overflow: hidden;
}

.h222 {
    width: 100%;
    display: flex;
    margin-top: 20px;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0 15% 0 15%;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    padding-bottom: 20px;
}

@media screen and (max-width: 600px) {
    #Glimpse {
        margin: 0;
        padding: 0;
    }
    .heading {
        margin: 0;
        padding: 0;
    }
}