#timeCounter{
    border: transparent;
    align-items: center;
    text-align: center;
    width: 150px;
    height: 120px;
    margin: 0;
    color: green;
    -webkit-text-stroke: 2px;
    -webkit-text-fill-color: green;
    /* -webkit-text-stroke-width: 1px; */
    /* font-size: 50px; */
}

/* hr{
    border-top: 10px solid #fff;
} */

.counter{
    display: flex;
    justify-content: space-between;
    font-size: 70px;
    margin: 0;
    align-items: center;
}

.counter p{
    font-size: 25px;
    margin: 0;
}


@media(max-width: 600px){
    icon{
        display:inline;
    }
    .counter{
        display: flex;
        justify-content: space-between;
        font-size: 50px;
        margin: 0;
        align-items: center;
    }
    
    .counter p{
        font-size: 16px;
        margin: 0;
    }
    #timeCounter{
        border: transparent;
        align-items: center;
        text-align: center;
        width: 70px;
        height: 120px;
        margin: 0;
        color: green;
        -webkit-text-stroke: 2px;
        -webkit-text-fill-color: green;
        /* -webkit-text-stroke-width: 1px; */
        /* font-size: 50px; */
    }
}