.card{
    background-image: url(../../assets/Card.png);
    background: content-box;
    border:1px solid #284550;
    box-shadow: 5px 5px 12px rgba(172, 112, 255, 0.33),
                -5px -5px 12px rgba(172, 112, 255, 0.33),
                5px 5px 10px rgba(172, 112, 255, 0.33),
                -5px -5px 10px rgba(172, 112, 255, 0.329);
    width: 17.5rem;

    /* height: fit-content; */
    filter: drop-shadow(5px 5px 10px rgba(172, 112, 255, 0.33));  
}

.Events{
    background: url('../../assets/bgprakarsh.png');
    height: fit-content;
    /* width: 100vw; */
    /* overflow: hidden; */
    /* background-size: contain; */
    background-repeat: no-repeat;
    /* position: relative; */
    /* min-width: 2000px; */
    z-index:0;
    margin: 5rem 0 ;
}
.bg{
    margin: 25px;
    
}
.container .card:hover{
    transform: scale(1.05);
    transition: 0.5s;

}
    

.imgc{
    width: 90%;
    padding-top: 15px;
}
.title{
    font-family: 'valorant';
    font-style: normal;
    font-weight: 400;
    background: linear-gradient(180deg, rgba(98, 212, 237, 0.8) -27.94%, rgba(172, 112, 255, 0.79) 76.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    text-shadow: 0px 4px 6px rgba(172, 112, 255, 0.33);
}
.description{
    color: rgba(165, 239, 255, 0.66);
    font-family: 'ubuntu';
    line-height: 18px;
    text-align: center;
}
.sevent-card{
    height: 360px;
}
.button{
    background: linear-gradient(180deg, rgba(98, 212, 237, 0.8) -27.94%, rgba(172, 112, 255, 0.79) 76.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */
    font-family: 'valorant';
    width: 75%;
    text-shadow: 0px 4px 6px rgba(172, 112, 255, 0.33);

}
