.project p {
  color: white;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}

.project .nav.nav-pills {
  width: fit-content;
  margin: 0 auto;
  border-radius: 100px;
}

.project .nav.nav-pills .nav-item {
  width: 50%;
}

.project .nav.nav-pills .nav-link {
  background-color: transparent;
  font-family: 'valorant';
  font-weight: 300px;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 900;
  text-transform: uppercase;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
 
.proj-imgbx img{
  /* border-radius: 0 0 15% 15%; */
  border-radius: 50%;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(
    111.17deg,
    rgba(0, 253, 101, 0.09) 0.84%,
    rgba(0, 253, 101, 0.38) 0.84%,
    rgba(0, 253, 101, 0) 73.9%,
    rgba(0, 253, 101, 0) 87.9%,
    rgba(0, 253, 101, 0) 98.91%
  );
  /* border: 1px solid rgba(0, 253, 101, 0.82); */
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.proj-imgbx {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  width: 240px;
  height: 310px;
  margin-bottom: 26px;
  margin-top: 15px;
}

.proj-imgbx::before {
  content: "";
  
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before {
  height: 100%;
}
.link a{
  color: #000;
  font-size: 30px;
  padding: 2px;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90.21deg,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.6)
  );
}

.project {
  padding: 2px;
  margin: 5px 0;
  font-family: ubuntu;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-imgbx{
  background: #13274b;
  /* width: 500px; */
  padding: 10px;
;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.link {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.linkimg {
  width: 50px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}
@media (max-width: 600px) {
  .head {
    left: -1rem;
  }
  .head .front-text {
    left: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .head {
    right: 3rem;
  }
  .head .front-text {
    left: -1rem;
  }
}




.our-team {
  padding: 20px 0 20px;
  margin-bottom: 30px;
  background-color: var(--bg);
  box-shadow: 5px 5px 12px rgba(172, 112, 255, 0.33),
                -5px -5px 12px rgba(172, 112, 255, 0.33),
                5px 5px 10px rgba(172, 112, 255, 0.33),
                -5px -5px 10px rgba(172, 112, 255, 0.329);
  text-align: center;
  overflow: hidden;
  position: relative;
}

.our-team .picture {
  display: inline-block;
  height: 150px;
  width: 150px;
  margin-bottom: 50px;
  z-index: 1;
  position: relative;
}

.our-team .picture::before {
  content: "";
  width: 100%;
  height: 0;
  border-radius: 50%;
  background-color: rgba(172, 112, 255, 0.33);
  position: absolute;
  bottom: 135%;
  right: 0;
  left: 0;
  opacity: 0.9;
  transform: scale(3);
  transition: all 0.3s linear 0s;
}

.our-team:hover .picture::before {
  height: 100%;
}

.our-team .picture::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(0, 253, 101, 0.38) 0.84%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.our-team .picture img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transform: scale(1);
  transition: all 0.9s ease 0s;
}

.our-team:hover .picture img {
  box-shadow: 0 0 0 14px #f7f5ec;
  transform: scale(0.7);
}

.our-team .title {
  display: block;
  font-size: 15px;
  color: #fff;
  text-transform: capitalize;
}

.our-team .social {
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: rgba(172, 112, 255, 0.33);
  position: absolute;
  bottom: -100px;
  left: 0;
  transition: all 0.5s ease 0s;
}

.our-team:hover .social {
  bottom: 0;
}

.our-team .social li {
  display: inline-block;
}

.our-team .social li a {
  display: block;
  padding: 10px;
  font-size: 17px;
  color: white;
  transition: all 0.3s ease 0s;
  text-decoration: none;
}

.our-team .social li a:hover {
  color: rgba(172, 112, 255, 0.33);
  background-color: transparent;
}
